import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { useFetch, useTranslator } from '@hooks';
import { EmailConfiguration } from '@models';
import { useAlert } from '@providers';
import { captureException } from '@sentry/nextjs';
import { FormHandles, SubmitHandler } from '@unform/core';
import { domainRegex } from '@utils';
import CheckIcon from '~/assets/svg/controls/check.svg';
import AttentionIcon from '~/assets/svg/controls/icon__attention-symbol-triangle.svg';
import { Box, Button, CardBody, CardHeader, InfoTooltip, Label, Text } from '~/components/elements';
import { InputFormGroup } from '~/components/forms';
import { routesName } from '~/locales/route';
import { SaveConfigurationAsync, VerifyDomainAppointmentAsync } from '~/services/emailConfigurationServiceApi';
import { PageTitle } from '~/styles';
import {
    CardStyled,
    DomainInputGroupStyled,
    FormContent,
    FormStyled,
    GroupContentStyled,
    InputBlockStyled,
    StatusStyled,
    TooltipContentStyled
} from './styles';

type EmailConfigurationTemplateProps = {
    configuration: EmailConfiguration;
};

// const items = [
//     {
//         id: 1,
//         type: 'txt',
//         name: 'email',
//         value: '"v=spf1 include:amazonses.com ~all"',
//         status: 1
//     },
//     {
//         id: 2,
//         type: 'cname',
//         name: 'azc6nejtwxmx3e7hd3ossd4cnxvagkc._domainkey',
//         value: '"v=spf1 include:amazonses.com ~all"',
//         status: 0
//     },
//     {
//         id: 3,
//         type: 'cname',
//         name: '4toy6adow2edjglq2nbrqsncf7paijai._domainkey',
//         value: '"v=spf1 include:amazonses.com ~all"',
//         status: 1
//     }
// ];

export const EmailConfigurationTemplate = ({ configuration }: EmailConfigurationTemplateProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { success, error } = useAlert();
    const router = useRouter();
    const {
        buttons,

        pages: {
            sendingTechnologySetting: {
                title: pageTitle,
                emailConfiguration: { title, content, alerts }
            }
        }
    } = useTranslator();
    const formRef = useRef<FormHandles>();

    const generateConfiguration: SubmitHandler<{ domain: string }> = async (formData) => {
        try {
            setIsLoading(true);
            formRef.current.setErrors({});

            const schema = yup.object().shape({
                domain: yup
                    .string()
                    .matches(domainRegex, { message: String(content.form.domain.validate) })
                    .trim()
                    .required(String(content.form.domain.required))
            });

            await schema.validate(formData, { abortEarly: false });

            await SaveConfigurationAsync(formData.domain);

            success(String(alerts.success), null, {
                onClose: async () => await router.push(routesName.root)
            });

            setIsLoading(false);
        } catch (exception) {
            if (exception instanceof yup.ValidationError) {
                const errorMessages = {};

                exception.inner.forEach((error) => (errorMessages[error.path] = error.message));
                formRef.current.setErrors(errorMessages);
            } else {
                error(String(alerts.failedTitle), String(alerts.failed));
                captureException(exception);
            }
            setIsLoading(false);
        }
    };

    const allowVerifyDomainDomainAppointment = !configuration.domain || configuration.isVerified !== true;
    const {
        data: { isVerified },
        mutate
    } = useFetch(
        allowVerifyDomainDomainAppointment && { url: 'verify-domain-appointment' },
        async () => (await VerifyDomainAppointmentAsync()).data,
        {
            fallbackData: { isVerified: configuration.isVerified },
            refreshInterval: 30_000
        }
    );

    return (
        <FormStyled ref={formRef} onSubmit={generateConfiguration} initialData={configuration}>
            <PageTitle>{String(pageTitle)}</PageTitle>
            <CardStyled type='group'>
                <CardHeader title={String(title)} />
                <CardBody>
                    <FormContent>
                        <GroupContentStyled>
                            <Label htmlFor='domain'>{String(content.domainInput.label)}</Label>
                            <DomainInputGroupStyled>
                                <InputFormGroup
                                    type='text'
                                    name='domain'
                                    placeholder={String(content.domainInput.placeholder)}
                                />
                                <Button type='submit' color='purple' inLoading={isLoading}>
                                    {String(buttons.generateConfigurations)}
                                </Button>
                            </DomainInputGroupStyled>
                        </GroupContentStyled>

                        <GroupContentStyled>
                            <Label>
                                {String(content.domainPointing.label)}
                                <InfoTooltip>
                                    <TooltipContentStyled>
                                        {String(content.domainPointing.tooltip)}
                                    </TooltipContentStyled>
                                </InfoTooltip>
                            </Label>
                            <InputBlockStyled>
                                <Box>
                                    <div className='title'>
                                        <Text as='strong' fontWeight='semiBold' size='lg'>
                                            {String(content.domainPointing.nsType)}
                                        </Text>
                                        {configuration.domain && (
                                            <StatusStyled
                                                as='i'
                                                size='xs'
                                                color={!isVerified ? 'oldGold' : 'darkMossGreen'}>
                                                {!isVerified ? <AttentionIcon /> : <CheckIcon />}
                                                {!isVerified
                                                    ? String(content.status.pending)
                                                    : String(content.status.checked)}
                                            </StatusStyled>
                                        )}
                                    </div>
                                    <Text size='md' lineHeight='base'>
                                        ns1.enviou.com.br <br />
                                        ns2.enviou.com.br <br />
                                        ns3.enviou.com.br
                                    </Text>
                                </Box>
                            </InputBlockStyled>

                            <Button
                                color='purple'
                                onClick={async () => await mutate()}
                                disabled={!allowVerifyDomainDomainAppointment}>
                                {String(buttons.verifyAppointment)}
                            </Button>
                        </GroupContentStyled>

                        {/* Será feito em outro momento */}
                        {/* <GroupContentStyled>
                            <Label>
                                {String(content.recordAppointment.label)}
                                <InfoTooltip>
                                    <TooltipContentStyled>
                                        {String(content.recordAppointment.tooltip)}
                                    </TooltipContentStyled>
                                </InfoTooltip>
                            </Label>

                            <ListStyled>
                                <ListHeaderStyled>
                                    <ListItemContentStyled>
                                        {Object.entries(content.recordsTable.header).map(([key, value]) => (
                                            <Text as='strong' size='lg' key={key}>
                                                {String(value)}
                                            </Text>
                                        ))}
                                    </ListItemContentStyled>
                                </ListHeaderStyled>
                                {items.map((item) => {
                                    return (
                                        <CustomListItemStyled key={item.id}>
                                            <ListItemContentStyled>
                                                <Text as='strong' size='xs'>
                                                    {item.type}
                                                </Text>
                                                <Text as='span' size='xs'>
                                                    <span>
                                                        {item.name}
                                                        <span>.meudominio.com.br</span>
                                                    </span>
                                                    <Button isIcon color='purple' isOutlined>
                                                        <CopyIcon />
                                                    </Button>
                                                </Text>
                                                <Text as='span' size='xs'>
                                                    {item.value}
                                                    <Button isIcon color='purple' isOutlined>
                                                        <CopyIcon />
                                                    </Button>
                                                </Text>
                                                <StatusStyled
                                                    as='i'
                                                    size='xs'
                                                    color={item.status === 0 ? 'oldGold' : 'darkMossGreen'}>
                                                    {item.status === 0 ? <AttentionIcon /> : <CheckIcon />}
                                                    {item.status === 0 ? 'Pendente' : 'Verificado'}
                                                </StatusStyled>
                                            </ListItemContentStyled>
                                        </CustomListItemStyled>
                                    );
                                })}
                            </ListStyled>

                            <Button color='purple'>{String(buttons.verifyAppointment)}</Button>
                        </GroupContentStyled> */}
                    </FormContent>
                </CardBody>
            </CardStyled>
        </FormStyled>
    );
};
